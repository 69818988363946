.resourceBooked .bookedTime h5 {
  font-family: "MyCustomFont";
  font-weight: 700;
  font-size: 47px;
  line-height: 36px;
  letter-spacing: 0%;
  text-align: center;
  color: #1d2939;
}
.resourceBooked .bookedTime p {
  font-family: "MyCustomFont";
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0%;
  text-align: center;
  color: #1d2939;
}
.resourceBooked .bookedList {
  width: 100%;
}
.bookedList .bookedResources {
  margin-top: 14px;
  background-color: rgb(255, 255, 255) (255, 240, 240);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eaecf0;
  padding: 18px 19px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.bookedList .bookedTime {
  border: 1px solid #6366f1;
}
.bookedList .bookedResources p {
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0px;
  color: #4e5d78;
  margin-bottom: 0px;
}
.bookedList .selectBookedTime span {
  background: #eff8ff;
  color: #175cd3;
  font-family: "MyCustomFont";
  font-weight: 800;
  font-size: 14px;
  padding: 2px 10px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}
