.resourceBox {
  display: flex;
  align-items: flex-start;
}

.resourceAdd {
  width: 100%;
}

.inputField {
  position: relative;
  width: 100%;
}

#addResource .profileImageUpload .profileImgView {
  padding: 24px 72px;
}

.resourceBox .rateChoose {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--Brand-Divider, #eaecf0);
}

.inputField span {
  position: absolute;
  color: var(--Gray-600, #475467);
  line-height: 24px; /* 150% */
  top: 0px;
  left: 0px;
  bottom: 0px;
  padding: 12px 100px 11px 12px;
  border: none;
  border-right-width: medium;
  border-right-style: none;
  border-right-color: currentcolor;
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  background: transparent;
  z-index: 1;
  border-right: 1px solid #d0d5dd;
}

.inputField.resourceName input {
  padding-left: 170px;
}

.inputField input::placeholder {
  color: #98a2b3;
  font-weight: 300;
}

.inputField input {
  width: 100%;
  border: 1px solid #d0d5dd;
  color: #171a20;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
}

.dropdownField {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.dropdownField-focused {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d0d5dd;
  border-radius: 12px 12px 0px 12px;
  -webkit-border-radius: 12px 12px 0px 12px;
  -moz-border-radius: 12px 12px 0px 12px;
  -ms-border-radius: 12px 12px 0px 12px;
  -o-border-radius: 12px 12px 0px 12px;
}

.dropdownField span {
  padding: 12px 100px 11px 12px;
  border: none;
  border-right-width: medium;
  border-right-style: none;
  border-right-color: currentcolor;
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--Gray-600, #475467);
  background: transparent;
  z-index: 1;
  border-right: 1px solid #d0d5dd;
}

.dropdownField-focused span {
  padding: 12px 100px 11px 12px;
  border: none;
  border-right-width: medium;
  border-right-style: none;
  border-right-color: currentcolor;
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--Gray-600, #475467);
  background: transparent;
  z-index: 1;
  border-right: 1px solid #d0d5dd;
}

.dropdownField-focused .dropdown,
.dropdownField-focused .dropdown button {
  width: 100%;
  text-align: left;
}

.dropdownField-focused .dropdown button.btn:first-child:active,
.dropdownField-focused .dropdown button.btn.show {
  border-color: transparent !important;
}

.dropdownField-focused .inputSvg {
  position: absolute;
  right: 10px;
  top: 14px;
  font-size: 12px;
}

.dropdownField .dropdown,
.dropdownField .dropdown button {
  width: 100%;
  text-align: left;
}

.dropdownField .dropdown button.btn:first-child:active,
.dropdownField .dropdown button.btn.show {
  border-color: transparent !important;
}

.dropdownField .inputSvg {
  position: absolute;
  right: 10px;
  top: 14px;
  font-size: 12px;
}

.dropdownInput {
  width: 100%;
}

#addResource .dropdownRate {
  height: 46px;
}

.dropdownRate {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.dropdownRate span {
  padding: 12px 150px 11px 12px;
  border: none;
  border-right-width: medium;
  border-right-style: none;
  border-right-color: currentcolor;
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
  background: transparent;
  z-index: 1;
  border-right: 1px solid #d0d5dd;
}

.dropdownRate .rateNumber {
  width: 100%;
  padding: 11px 12px;
  display: flex;
  align-items: center;
}

.dropdownRate .rateOption {
  padding: 12px 12px 11px 12px;
  border: none;
  border-right-width: medium;
  border-right-style: none;
  border-right-color: currentcolor;
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
  background: transparent;
  z-index: 1;
  border-left: 1px solid #d0d5dd;
}

.dropdownRate .rateOption .dropdown button {
  /* align-items: center; */
  display: flex;
}

.dropdownRate .rateOption .dropdown button svg {
  margin-left: 15px;
}

.dropdownRate .rateNumber input,
.dropdownRate .rateNumber input:focus-visible {
  border: none !important;
  box-shadow: none !important;
}

.typeCapacity {
  display: flex;
  justify-content: space-between;
}

.typeCapacity .dropdownInput {
  width: 49%;
}
.resourceBtn {
  display: flex;
  justify-content: end;
}
.resourceBtn button {
  margin-top: 24px;

  box-shadow: 0px 2.4000000953674316px 6px 0px #0619380f;
  padding: 12px 50px;
  border: none;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.resourceBtn .save {
  background: #6366f1;
  color: #fff;
}
.resourceBtn .delete {
  background-color: rgba(254, 243, 242, 1) !important;
  color: rgba(180, 35, 24, 1) !important;
  border: 1px solid rgba(180, 35, 24, 1) !important;
  margin-right: 10px;
}
.resourceAdd .typeCapacity .dropdownField span,
.resourceAdd .typeCapacity .resourceRate span,
.resourceAdd .resourceName span {
  width: 30%;
}

.resourceAdd .typeCapacity .dropdownField-focused span,
.resourceAdd .typeCapacity .resourceRate span,
.resourceAdd .resourceName span {
  width: 30%;
}

.resourceAdd .typeCapacity .resourceRate input,
.resourceAdd .resourceName input {
  padding-left: calc(30% + 12px);
}

.dropdown-main:focus {
  border-radius: 12px 12px 0px 12px !important;
  background-color: #000 !important;
}
.dropdown-edge {
  margin-top: 2px !important;
  border-radius: 0px 0px 12px 12px !important;
  width: 100.3% !important;
}

.dropdown-edge-2 {
  margin-top: 13px !important;
  border-radius: 0px 0px 12px 12px !important;
  width: 100.3% !important;
}

.dropDown-focus:hover {
  background-color: #f4f7f8 !important;
  border-radius: 12px !important;
}
.dropDown-focus {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 90% !important;
  height: 44px !important;
  margin: 0px 10px !important;
  padding-top: 10px !important;
}

.dropdown-menu-margin {
  margin-left: -1px !important;
}
.addResourceHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 28px;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 28px;
}
.addResourceHeading div {
  display: flex;
  align-items: center;
}
.addResourceHeading p {
  font-family: "MyCustomFont";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
  margin-bottom: 0px;
  margin-left: 12px;
}

.resourceOption button {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  background-color: #fff;
  border: 1px solid rgba(208, 213, 221, 1);
  padding: 10px 16px;
  color: rgba(29, 41, 57, 1);
}
.resourceOption .active {
  background: rgba(249, 250, 251, 1);
}
.resourceOption button:nth-child(1) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
}

.resourceOption button:nth-child(2) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.resourceDescription {
  width: 100%;
  /* border: 1px solid rgba(234, 236, 240, 1); */
  padding: 24px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.resourceDescription .generateInvoice {
  margin-bottom: 0px;
  margin-top: 0px;
}
.resourceDescription .quill {
  margin-top: 25px;
}
.resourceDescription .ql-container.ql-snow {
  height: 200px;
}
